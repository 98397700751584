import { ReactNode } from 'react';
import styled from 'styled-components';

import { StyleProps } from './StyleProps';
import { Text } from './Text';

export const Underline = styled(
  ({
    tag = 'span',
    ...props
  }: StyleProps & {
    children?: ReactNode;
    analyticsData?: {
      eventName: string;
      eventProperties?: Record<string, unknown>;
    };
    dangerouslySetInnerHTML?: { __html: string };
    id?: string;
    tag?: string;
  }) => (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Text tag={tag} styleAs="underline" {...props} />
  ),
)`
  @keyframes a-underline {
    from {
      background-size: 0 5px;
    }

    to {
      background-size: 100% 5px;
    }
  }

  background-repeat: no-repeat;
  background-size: 0 5px;
  animation: a-underline 1s cubic-bezier(0.215, 0.61, 0.355, 1) 1s forwards;
`;
