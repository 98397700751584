import { SalariesRecentSubmission } from 'modules/salaries/types/SalariesRecentSubmission';
import { getJson } from 'utils/http/getJson';

export async function salariesFetchRecentSubmissions(): Promise<
  SalariesRecentSubmission[]
> {
  return getJson<{
    recentSubmissions: SalariesRecentSubmission[];
  }>('/data/salary-surveys/recent-submissions').then(
    ({ recentSubmissions }) => recentSubmissions,
  );
}
