import { Button } from 'components/Button/Button';
import { ButtonVariant } from 'components/Button/Button.types';
import { showModal } from 'zustand-stores/modalStore';

type Props = {
  disabled?: boolean;
  shareUrl: {
    en: string;
    es: string;
    pt: string;
  };
  onClick?: () => void;
  title?: string;
  size?: 'small' | 'large';
  variant?: ButtonVariant;
  messageOverride?: string;
  emailMessageOverride?: string;
  emailSubjectOverride?: string;
  buttonText?: string;
};

export function ShareURLButton({
  buttonText,
  disabled,
  messageOverride,
  emailMessageOverride,
  emailSubjectOverride,
  onClick,
  shareUrl,
  size,
  title,
  variant,
}: Props) {
  return (
    <Button
      type="button"
      icon={{ type: 'normalized', name: 'share' }}
      aria-label="Share"
      data-qa-id="share-button"
      variant={variant || 'secondary'}
      disabled={disabled}
      size={size}
      onClick={(e) => {
        e.preventDefault();

        if (onClick) {
          onClick();
        }

        showModal('SHARE_LISTING', {
          shareUrl,
          // @ts-expect-error TS(2345): Argument of type '{ shareUrl: { en: string; es: st... Remove this comment to see the full error message
          title,
          messageOverride,
          emailMessageOverride,
          emailSubjectOverride,
        });
      }}
    >
      {buttonText || getText('Share')}
    </Button>
  );
}
