import { ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { H4 } from 'components/Text/H4';
import { colors, cssBreakpoints, gutterWidth, maxWidth } from 'theme/theme';
import { getStaticImageSrc } from 'utils/getImageSrc';

// Height of each table cell
const TABLE_CELL_HEIGHT = 40;

// Number of table rows that are visible at any given time
const TABLE_ROW_COUNT = 6;

export const aCycle = (data: ReactNode[][]) =>
  keyframes`
  ${data.map(
    (_, i) => css`
      ${(100 / (data.length - TABLE_ROW_COUNT)) * i}% {
        transform: translateY(${-TABLE_CELL_HEIGHT * i}px);
      }
    `,
  )}
`;

export const RecentSubmissionsContainer = styled.div`
  justify-content: center;
  background-image: url('${() => getStaticImageSrc('salary-bg.svg')}');
  background-size: cover;
  color: ${colors.white};
  display: flex;
  width: 100%;
`;

export const RecentSubmissionsWrapper = styled.div`
  align-items: center;
  display: flex;
  max-width: ${maxWidth[4]}px;
  padding: ${gutterWidth * 2}px;
`;

export const RecentSubmissionsHeading = styled.div`
  text-align: center;

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    padding-right: 120px;
    text-align: left;
  }
`;

export const TableHeading = styled(H4)`
  margin-bottom: 0;
  padding-left: 8px;
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-right: 30px;
  padding-left: 30px;
  background: ${colors.white};
  color: ${colors.mediumContentGrey};
  border-radius: 10px;
  font-size: 16px;

  @media all and (max-width: ${cssBreakpoints.lgDown}) {
    display: none;
  }
`;

export const TableBodyContainer = styled.div`
  max-height: ${TABLE_ROW_COUNT * TABLE_CELL_HEIGHT}px;
  overflow-y: hidden;
`;

export const TableBody = styled.div<{
  $animationName: ReturnType<typeof keyframes>;
  $itemCount: number;
}>`
  animation-name: ${(props) => props.$animationName};
  animation-duration: ${(props) => 3 * (props.$itemCount - 4)}s;
  animation-iteration-count: infinite;
`;

export const TableCell = styled.div`
  height: ${TABLE_CELL_HEIGHT}px;
  padding: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SurveyButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
