import { CmsApiRecentSalariesBlockData } from 'modules/cms/api/types/blocks/CmsApiRecentSalariesBlock';
import { RecentSubmissions } from 'modules/salaries/components/RecentSubmissions/RecentSubmissions';

type Props = {
  data: CmsApiRecentSalariesBlockData;
  blockIndex: number;
  pageUpdated: string;
};

export function CmsRecentSalariesBlock({
  data,
  blockIndex,
  pageUpdated,
}: Props) {
  return (
    <RecentSubmissions
      alreadySubmitted={false}
      surveyLoading={false}
      cmsData={{ ...data, blockIndex, pageUpdated }}
    />
  );
}
